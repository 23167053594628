import React from 'react';
import './header.min.css';
import {Navbar, Nav, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Logo from '../../assets/images/Logo.png';
// import Switch from "react-switch";

import * as Scroll from "react-scroll";

const Links = Scroll.Link;

const Header = (props) => {
  return (
    <Navbar collapseOnSelect expand="md" fixed="top" className={props.shrink}>
      <Container>
        <Navbar.Brand href="#home"><img src={Logo} alt={Logo} height={60}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='ms-auto align-items-end'>
            {document.location.href.includes('about') || document.location.href.includes('specials') || document.location.href.includes('quote')?<>
              <Link to="/" className='nav-link'>Home</Link>
              {!document.location.href.includes('quote')?
                <Link to="/quote" className='nav-link'>Quote</Link>:null}
              {!document.location.href.includes('about')?
                <Link to="/about" className='nav-link'>About</Link>:null}</>:<>
              <Links to="home" className='nav-link' spy={true} smooth={true} offset={0} duration={500}>Home</Links>
              <Links to="promo" className='nav-link' spy={true} smooth={true} offset={0} duration={500}>Promo</Links>
              <Links to="services" className='nav-link' spy={true} smooth={true} offset={0} duration={500}>Services</Links>
              <Links to="projects" className='nav-link' spy={true} smooth={true} offset={0} duration={500}>Projects</Links>
            {!document.location.href.includes('quote')?
              <Link to="/quote" className='nav-link'>Quote</Link>:null}
            {!document.location.href.includes('about')?
              <Link to="/about" className='nav-link'>About</Link>:null}</>}
                {/* <Nav className="ms-2 d-flex align-items-center" style={{color: 'white'}}>
                  <span className='me-2'>Dyslexia:</span>
                  <Switch 
                      onChange={() => props.onSetDyslexia(props.font ? false : true)} 
                      checked={props.font} 
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={18}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={28}
                      className="react-switch"
                      id="material-switch"
                  />
                </Nav> */}
              {/* </div> */}
            </Nav>            
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Header;