import React, { Component, useEffect } from 'react';
import {Route, Routes, Navigate, useLocation } from 'react-router-dom'

import Layout from '../HOC/Layout';
import Home from './Home/Home';
import About from './About/About';
import Quote from '../Components/Quote/Quote';

import Landing from './Landing/Landing';
import Error404 from '../404/404';

import { 
   setNavbar, 
   showB2T, 
   setEmailed, 
   setEmail, 
   setName, 
   setDelivery, 
   setStreet, 
   setState, 
   setNumber, 
   setDescription, 
   setNotify } from '../Reducer/Action';

import { connect } from 'react-redux';

const mapStateToProps = state => {
   return {
      navbar: state.BaseReducer.navbar,
      b2t: state.BaseReducer.b2t,
      emailed: state.emailReducer.emailed,
      email: state.emailReducer.email,
      state: state.emailReducer.state,
      delivery: state.emailReducer.delivery,
      street: state.emailReducer.street,
      number: state.emailReducer.number,
      name: state.emailReducer.name,
      description: state.emailReducer.description,
      notify: state.emailReducer.notify,
   }
}

const mapDispatchToProps = (dispatch) => {
   return { 
      onSetNavbar: (link) => dispatch(setNavbar(link)),
      onShowB2T: (link) => dispatch(showB2T(link)),
      onSetEmailed: (link) => dispatch(setEmailed(link)),
      onSetDelivery: (link) => dispatch(setDelivery(link)),
      onSetEmail: (link) => dispatch(setEmail(link)),
      onSetName: (link) => dispatch(setName(link)),
      onSetStreet: (link) => dispatch(setStreet(link)),
      onSetState: (link) => dispatch(setState(link)),
      onSetNumber: (link) => dispatch(setNumber(link)),
      onSetDescription: (link) => dispatch(setDescription(link)),
      onSetNotify: (link) => dispatch(setNotify(link)),
   }
}
class App extends Component {
   
   ScrollToTop = () => {
      const { pathname } = useLocation();
    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
   }
   componentDidMount() {
      window.addEventListener('scroll', this.Scroller, {passive: true});
   }

   Scroller = () => {
      if(window.scrollY <= 30 && window.scrollY >= 0){
         this.props.onSetNavbar('')
         this.props.onShowB2T(false);
      } else if((window.scrollY >= 30 && window.scrollY <= 60) || (window.scrollY >= 31 && this.props.navbar === undefined) || (window.scrollY >= 31 && this.props.navbar === '')) {
         this.props.onSetNavbar('shrink');
         this.props.onShowB2T(true);
      }
   }
   
   render() {
     return (
       <div>
         <Routes>
            <Route path="/" exact element={
               <Layout shrink={this.props.navbar} b2t={this.props.b2t}>
                  <Home 
                     s2t={this.ScrollToTop} /> 
               </Layout>
            }/>
            <Route path="/about" exact element={ 
               <Layout shrink={this.props.navbar} b2t={this.props.b2t}>
                  <About s2t={this.ScrollToTop}/>
               </Layout>
            }/>
            <Route path="/quote" exact element={ 
               <Layout shrink={this.props.navbar} b2t={this.props.b2t}>
                  <Quote 
                     s2t={this.ScrollToTop} 
                     name={this.props.name}
                     email={this.props.email}
                     number={this.props.number}
                     street={this.props.street}
                     delivery={this.props.delivery}
                     description={this.props.description}
                     state={this.props.state}
                     emailed={this.props.emailed}
                     notify={this.props.notify}
                     onSetName={this.props.onSetName}
                     onSetEmail={this.props.onSetEmail}
                     onSetNumber={this.props.onSetNumber}
                     onSetStreet={this.props.onSetStreet}
                     onSetDelivery={this.props.onSetDelivery}
                     onSetState={this.props.onSetState}
                     onSetEmailed={this.props.onSetEmailed}
                     onSetDescription={this.props.onSetDescription}
                     onSetNotify={this.props.onSetNotify} />
               </Layout>
            }/>
            <Route path="/landing/:id" exact element={ 
               <Layout shrink={this.props.navbar} b2t={this.props.b2t}>
                  <Landing s2t={this.ScrollToTop}/>
               </Layout>
            }/>
            <Route path='/denied' element={<Error404 />} />
            <Route path="*" element={<Navigate replace to="/denied" />} />               
         </Routes>
     </div>
     );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
