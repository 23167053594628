import React from "react";
import Section from "../Style/Style";
import "./intro.min.css";

const Intro = (props) => {
  return (
    <div className="intro overlay-6" id="home">
      <div className="container z-index">
        <h1 className={`mb-0 ${props.font ? "disl-font" : ""}`}>
          Heff's Fabrications
        </h1>
        <h4 className={props.font ? "disl-font" : ""}>
          Where Affordability Meets Excellence in Riverton, SA!
        </h4>
      </div>
      <Section color="var(--color-bg-grey)" />
    </div>
  );
};

export default Intro;
