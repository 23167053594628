import React from 'react';
import Section from '../Style/Style';
import QuoteForm from './QuoteForm/QuoteForm';
import Notifications from '../Notification/Notification';

const Quote = (props) => {
    props.s2t();
    return (
        <div>
            <Notifications title={`Thank You ${props.name}`} message={`Your request is sent to Shawn, He will be in contact with you on ${props.number.length>8?props.number:props.email}`} show={props.notify} />
      
            <div className="about section overlay-6" id="home">
                <div className="container grid-xl z-index">
                    <h1 className={`${props.font ? "disl-font ":""} mb-0`}>REQUEST QUOTE</h1>
                    <h4 className={props.font ? "disl-font ":""}>Heff's Fabrications</h4>
                </div>
                <Section color="var(--color-bg-grey)"/>
            </div>
            <div className='section section-sm pb-120 bg-grey'>
                <div className='container'>
                    <div className="text-center" style={{marginBottom: '30px'}}>
                        <h3 className={`title ${props.font ? "disl-font" : ""}`}>Request a Quote Today!</h3>
                        <p className={props.font ? "disl-font" : ""}>Fill in the form below and i will get back to you</p>
                    </div>
                    <div className='card card-body p-4 rounded-4'>
                        <QuoteForm 
                            name={props.name}
                            email={props.email}
                            number={props.number}
                            street={props.street}
                            delivery={props.delivery}
                            description={props.description}
                            state={props.state}
                            emailed={props.emailed}
                            onSetName={props.onSetName}
                            onSetNumber={props.onSetNumber}
                            onSetEmail={props.onSetEmail}
                            onSetStreet={props.onSetStreet}
                            onSetDelivery={props.onSetDelivery}
                            onSetState={props.onSetState}
                            onSetEmailed={props.onSetEmailed}
                            onSetDescription={props.onSetDescription}
                            onSetNotify={props.onSetNotify} />
                    </div>
                </div>
                <Section color="#282828"/>
            </div>
        </div>
    );
}

export default Quote;