import React from 'react';
import './b2t.min.css';
import image from '../../assets/images/gotop.png';

import * as Scroll from "react-scroll";
const Links = Scroll.Link;

const Back2Top = (props) => {
    // console.log(props.b2t)
  return (
        <Links
          to="home"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className={`b2t ${props.b2t?"show":"hide"}`}>
            <img src={image} alt={image} className="img-fluid" />
          
        </Links>
  );
}

export default Back2Top;