import React,{useState, useEffect} from 'react';
import Section from '../Style/Style';
//https://graphql.contentful.com/content/v1/spaces/saw435ab77o2/explore?access_token=NX8eE9-An6hqIT6cKwrI3h-Boe4kH5satQwyPDxEwzM
const Posts = (props) => {
    const query = `{
        projectsCollection {
          items {
            projectName
             photo{
              url
            }
            description
          }
        }
    }`;
    const [page, setPage] = useState(null);
    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/saw435ab77o2/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer NX8eE9-An6hqIT6cKwrI3h-Boe4kH5satQwyPDxEwzM",
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
    
            // rerender the entire component with new data
            setPage(data.projectsCollection.items);
          });
      }, [query]);
    if(!page){
        return 'Loading...';
    } else {
        // console.log(page);
        return (
            <div className="section bg-grey" style={{paddingBottom: '120px', overflow: 'hidden'}} id="projects">
                <div className="container">
                    <div className="text-center" style={{marginBottom: '30px'}}>
                        <h3 className={`title ${props.font ? "disl-font" : ""}`}>Projects</h3>
                        <p className={props.font ? "disl-font" : ""}>Photo gallary of recent projects</p>
                    </div>
                    <div className="row align-items-stretch justify-content-center">
                        {page.map((items, i) => {
                            return (
                                <div className='col-12 col-md-6 col-lg-3 d-flex mb-4' key={i}>
                                    <div className="panel position-relative bg-white rounded-4 pt-2 pb-3 w-100 shadow">
                                        <div style={{height: '90px'}} className='d-flex align-items-center justify-content-center'>
                                            <h4 style={{fontWeight: 600}} className={`title mb-0 text-center ${props.font ? "disl-font" : ""}`}>{items.projectName}</h4>
                                        </div>
                                        <hr/>
                                        <div style={{height: '300px', overflow: 'hidden'}} className='mb-2 d-flex align-items-center justify-content-center w-100'>
                                            <img src={items.photo.url} alt={items.projectName} style={{minWidth: '100%', position: 'relative'}}/>
                                        </div>
                                        <hr/>
                                        <div className="information pt-1">                                            
                                            <p className={props.font ? "disl-font" : ""}>{items.description}</p>
                                        </div>
                                    </div>                                    
                                </div>
                            )
                        })}
                        <div className="col-12 col-lg-6 d-flex">
                        
                        </div>
                    </div>
                </div>
                <Section color="#282828"/>
            </div>
        );
    }
}

export default Posts;