import React from 'react';
import './specials.css';
import { useParams } from 'react-router-dom';

import Specials from './Specials/Specials';
import Section from '../../Components/Style/Style';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
const Landing = (props) => {
  props.s2t();
  console.log(props)
  return (
        <div className="landing overlay-6" id="home">
           {props.params.id === "specials"?
           <Specials onShowQuoteModel={props.onShowQuoteModel} font={props.font}  onSetImage={props.onSetImage} onSetImageCap={props.onSetImageCap} CallFancy={props.CallFancy}/>
           :props.params.id === "quotes"?
           <div>

           </div>
           :null}
           <Section color="#282828"/>
        </div>
  );
}

export default withParams(Landing);