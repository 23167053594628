import React from 'react';
import '../about.min.css';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Section from '../../../Components/Style/Style';

const Section_Features = (props) => {
  return (
        <div className="section bg-grey">
            <div className="container">
                <div className="row align-items-stretch justify-content-center">
                    <div className="row col-12 col-lg-4 text-center mb-3 d-flex justify-content-center">
                        <div className="card-body bg-white rounded-5 p-4">
                        <i style={{fontWeight: 700, fontSize: '3rem'}} className="lni lni-comments-alt"></i>
                            <div className="mt-2">
                                <h3 className={props.font ? "disl-font" : ""}>Communication</h3>
                                <p className={props.font ? "disl-font" : ""}>I have great communication and easy to talk too, willing to go the extra mile to bring you the product you deserve</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row col-12 col-lg-4 mx-lg-4 text-center mb-3 d-flex justify-content-center">
                        <div className="card-body bg-white rounded-5 p-4">
                            <i style={{fontWeight: 700, fontSize: '3rem'}} className="lni lni-construction"></i>
                            <div className="mt-2">
                                <h3 className={props.font ? "disl-font" : ""}>Workshop</h3>
                                <p className={props.font ? "disl-font" : ""}>After receiving your order, your product will be fabricated in my workshop with the latest equipment on the market for metal fabrication</p>
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 col-lg-4 text-center mb-3 d-flex justify-content-center">
                        <div className="card-body bg-white rounded-5 p-4">
                            <i style={{fontWeight: 700, fontSize: '3rem'}} className="lni lni-construction"></i>
                            <div className="mt-2">
                                <h3 className={props.font ? "disl-font" : ""}>Delivery</h3>
                                <p className={props.font ? "disl-font" : ""}>After your product is completed, I offer delivery to your location for a small fee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Section color="var(--color-white)"/>
        </div>
  );
}

export default Section_Features;