import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Content/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/lineicons.min.css'
import './assets/css/index.min.css';
import './assets/css/App.min.css';
import 'normalize.css/normalize.css';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
// import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { BaseReducer, emailReducer } from './Reducer/Reducer';

import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// const logger = createLogger();
const rootReducer = combineReducers({ BaseReducer, emailReducer });
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware/*, logger*/));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
